import { useState, useEffect } from "react";
import { toast } from 'react-toastify';

export const useErrors = () => {
    const [errors, setErrors] = useState<any[] | null>(null);


    const showError = () => {
        if (errors) {
            errors.forEach(error => toast.error(error.message));
            setErrors(null);
        }
    }

    useEffect(() => {
        if(errors)
            showError();
    }, [errors])
    
    return {
        errors,
        setErrors
    } 
}