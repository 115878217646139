import { RouterApp } from './Router';
import { BrowserRouter } from 'react-router-dom';;

import { LoadingContextProvider } from './core/context/loading/LoadingContext.provider';
import { AuthContextProvider } from './core/context/auth/AuthContext.provider';
import { ServiceContextProvider } from './core/context/service/CustomService.provider';
import { ErrorsContextProvider } from './core/context/error/ErrorContext.provider';
import { GraphQlProvider } from './core/context/graphql/GraphqlContext.provider';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';


function App() {
 
  return (
    <BrowserRouter>
      <LoadingContextProvider>
        <ErrorsContextProvider>
          <ServiceContextProvider>
            <AuthContextProvider>
              <GraphQlProvider>
                  <RouterApp/>
              </GraphQlProvider>
            </AuthContextProvider>
          </ServiceContextProvider>
        </ErrorsContextProvider>
      </LoadingContextProvider>
    </BrowserRouter>
  );
}

export default App;