import React, { createContext, useContext, FC } from 'react';
import { IPropsContext } from '../../../schemas/interface/IPropsContext.types';
import { IUseAuthContext } from './AuthContext.types';
import { UseAuth } from './useAuth';


const defaultAuthContext: IUseAuthContext = {
    signUp: () => false,
    singIn: () => false,
    singInWithGoogle: ()=> {},
    singInWithApple: ()=> {},
    logOut: ()=> {},
    verifyCode: ()=> {},
    registerPassKey: ()=> {},
    authenticatePassKey: ()=> {},
    settingTwoFactor: ()=> {},
    activeCode: ()=> {},
    setUserTwoFactor: () => {},
    sendCode:  ()=> {},
    isAuthenticated: false,
    error: null,
    userTwoFactor: null,
    userActive: null,
};

export const AuthContext = createContext<IUseAuthContext>(defaultAuthContext);

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider: FC<IPropsContext> = ({ children }) => {
  const values = UseAuth();

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
