import { ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client';

import { IUseGraphQlPropsProvider } from './GraphqlContext.types';

const client = new ApolloClient({
    uri: process.env.REACT_APP_API_URL,
    cache: new InMemoryCache()
});

export const GraphQlProvider = ({ children }: IUseGraphQlPropsProvider) => (
    <ApolloProvider client={client}>
        {children}
    </ApolloProvider>
);