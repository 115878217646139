import { Route, Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../context/auth/AuthContext.provider";


const GuardRouter = () => {
    const { isAuthenticated } = useAuthContext();

    return(
        isAuthenticated ? <Outlet/> : <Navigate to="/auth" />
    )
}


export default GuardRouter;