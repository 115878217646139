import React, { createContext, useContext, FC } from 'react';
import { IUseErrorsContext } from './ErrorContext.types';
import { IPropsContext } from '../../../schemas/interface/IPropsContext.types';
import { useErrors } from './useError';


const defaultErrorsContext: IUseErrorsContext = {
  errors: null,
  setErrors: () => {},
};

export const ErrorsContext = createContext<IUseErrorsContext>(defaultErrorsContext);

export const useErrorsContext = () => useContext(ErrorsContext);

export const ErrorsContextProvider: FC<IPropsContext> = ({ children }) => {
  const values = useErrors();

  return <ErrorsContext.Provider value={values}>{children}</ErrorsContext.Provider>;
};
