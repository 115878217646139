import { Fragment, Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import GuardRouter from "./core/guard/router.guard";
import { useLoadingContext } from "./core/context/loading/LoadingContext.provider";
import { Backdrop, CircularProgress } from "@mui/material";
import { ToastContainer } from "react-toastify";

const Home = lazy(() => import('./pages/public/Home'));
const Auth = lazy(() => import('./pages/public/Auth'));
const Login = lazy(() => import('./pages/public/Auth/Login'));
const SingUp = lazy(() => import('./pages/public/Auth/SingUp'));
const Setting2FAPage = lazy(() => import('./pages/public/Auth/Setting2FA'));
const MultiFactorPage = lazy(() => import('./pages/public/Auth/Multifactor'));
const ActivePage = lazy(() => import('./pages/public/Auth/Active'));
const DashBoard = lazy(() => import('./pages/private/Dashboard'));
const ContentfulPage = lazy(() => import('./pages/private/ContentFull'));

const Redirect = ({path}:{path: string}) => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate(path)
    }, []);

    return <></>;
}


export const RouterApp = () => {
    const { loading } = useLoadingContext();
    const [open, setOpen] = useState<boolean>(false);
  
  
    useEffect(()=> {
      if(loading > 0) setOpen(true);
      else setOpen(false);
    },[loading])
  

    return (
        <Fragment>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" Component={Home} />
                    <Route path="/auth" element={<Auth />}>
                        <Route path="/auth/" element={<Login />} />
                        <Route path="/auth/register" element={<SingUp />} />
                        <Route path="/auth/active" element={<ActivePage />} />
                        <Route path="/auth/2fa/setting" element={<Setting2FAPage />} />
                        <Route path="/auth/2fa/multifactor" element={<MultiFactorPage />} />
                    </Route>
                    <Route element={<GuardRouter />}>
                        <Route path="/dashboard" element={<DashBoard/>} />
                        <Route path="/dashboard/:page" element={<ContentfulPage/>} />
                    </Route>
                    <Route path="*" element={<Redirect path="/auth"/>} />
                </Routes>
            </Suspense>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ToastContainer/>
        </Fragment>

    );
}
