import React, { createContext, useContext, FC } from 'react';
import { IUseLoadingContext } from './LoadingContext.types';
import { IPropsContext } from '../../../schemas/interface/IPropsContext.types';
import { useLoading } from './useLoading';


const defaultLoadingContext: IUseLoadingContext = {
  loading: 0,
  loadingPartial: [],
  setLoading: () => {},
  setLoadingPartial: () => {},
};

export const LoadingContext = createContext<IUseLoadingContext>(defaultLoadingContext);

export const useLoadingContext = () => useContext(LoadingContext);

export const LoadingContextProvider: FC<IPropsContext> = ({ children }) => {
  const values = useLoading();

  return <LoadingContext.Provider value={values}>{children}</LoadingContext.Provider>;
};
