import { OAuthProvider, GoogleAuthProvider } from "firebase/auth";

export const GoogleProvider = new GoogleAuthProvider();

export const AppleProvider = new OAuthProvider('apple.com');


export default {
    AppleProvider,
    GoogleProvider
}