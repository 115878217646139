const BASE_URI = 'https://us-central1-vessel-2024.cloudfunctions.net/app'

export const AUTH_SERVICE = {
    SEND_EMAIL: `${BASE_URI}/auth/send_email`,
    SIGN_UP: `${BASE_URI}/auth/signup`,
    ACTIVATE: `${BASE_URI}/auth/activate`,
    SIGN_IN: `${BASE_URI}/auth/signin`,
    GET_2FA: `${BASE_URI}/2fa/init`,
    SETTING_2FA: `${BASE_URI}/2fa/setting`,
    VERIFICATE: `${BASE_URI}/2fa/verificate`,
    REGISTER_OPT_KEYPASS: `${BASE_URI}/2fa/registerpass/start`,
    VERIFY_REGISTER_KEYPASS: `${BASE_URI}/2fa/registerpass/finish`,
    AUTHENTICATE_OPT_KEYPASS: `${BASE_URI}/2fa/authnticatedpass/start`,
    VERIFY_AUTHENTICATE_KEYPASS: `${BASE_URI}/2fa/authnticatedpass/finish`,
}