import { useEffect, useState } from "react"
import {
    signInWithPopup,
    signOut,
    onAuthStateChanged,
    UserCredential,
} from "firebase/auth";
import { AppleProvider, GoogleProvider } from "../../firebase/firebase.providers";
import { startRegistration, startAuthentication } from '@simplewebauthn/browser';

import { IUserParametters } from "./AuthContext.types";
import { useLoadingContext } from "../loading/LoadingContext.provider";
import { auth } from "../../firebase/firebase";

import { AUTH_SERVICE } from "./AuthContext.service";
import { useNavigate } from "react-router-dom";
import { useServiceContext } from "../service/CustomService.provider";
import { useErrorsContext } from "../error/ErrorContext.provider";

export const UseAuth = () => {
    const navigate = useNavigate();
    const { customAxios } = useServiceContext();
    const { setLoading } = useLoadingContext();
    const { setErrors } = useErrorsContext();

    const [tokenActive, setTokenActive] = useState<string>('');
    const [userActive, setUserActive] = useState<{ email: string, isActive: boolean } | null>(null);
    const [userTwoFactor, setUserTwoFactor] = useState<any | null>(null);
    const [user, setUser] = useState<any | null>(null);
    const [error, setError] = useState<any | null>(null);
    const [isAuthenticated, setIsAuthenticate] = useState<boolean>(false);

    const sendCode = async () => {
        const res = await customAxios(
            {
                url: AUTH_SERVICE.SEND_EMAIL,
                method: 'POST',
                data: {},
                headers: {
                    Authorization: `Bearer ${tokenActive}`,
                },
            }
        );

        if (res && res.active_token)
            setTokenActive(res.active_token)

        return res;
    }

    const signUp = async ({
        email,
        password,
    }: IUserParametters) => {
        const res = await customAxios({
            url: AUTH_SERVICE.SIGN_UP,
            method: 'POST',
            data: {
                email,
                password,
            }
        });

        if (res) {
            setTokenActive(res.active_token)
            setUserActive({email: email, ...res});
        }

        return res;
    }

    const activeCode = async ({ code }: { code: string }) => {

        const res = await customAxios({
            url: AUTH_SERVICE.ACTIVATE,
            method: 'POST',
            data: { code },
            headers: {
                Authorization: `Bearer ${tokenActive}`,
            },
        });

        if (!res) return res;

        const twoFactor = await getInitTwoFactor({ access_token: res.access_token });

        setTokenActive('');
        setUserTwoFactor({ ...twoFactor, email: userActive?.email, ...res });
        setUserActive(null);

        return res;
    }

    const singIn = async ({
        email,
        password,
    }: IUserParametters) => {
        const res = await customAxios({
            url: AUTH_SERVICE.SIGN_IN,
            method: 'POST',
            data: {
                email,
                password,
            }
        });

        if (!res) return res;

        const twoFactor = await getInitTwoFactor({ access_token: res.access_token });

        if (!twoFactor) return twoFactor;

        setUserTwoFactor({ ...twoFactor, email, ...res });

        return res;
    }

    const settingTwoFactor = async ({ codeTwo }: { codeTwo: number }) => {
        if (!userTwoFactor.firtsLogin) return false;

        const res: any = await customAxios({
            url: AUTH_SERVICE.SETTING_2FA,
            method: 'POST',
            data: { codeTwoFactor: codeTwo },
            headers: {
                Authorization: `Bearer ${userTwoFactor.access_token}`,
            },
        });

        if (!res) return res;

        await getInitTwoFactor({ access_token: userTwoFactor.access_token });

        if (typeof res === 'string')
            setUserTwoFactor({ imageQr: res, codeTwofactor: codeTwo, ...userTwoFactor });

        

        return true;
    };

    const verifyCode = async ({ code }: { code: string }) => {
        if (userTwoFactor.firtsLogin) return navigate('/auth/2fa/setting');

        const res = await customAxios({
            url: AUTH_SERVICE.VERIFICATE,
            method: 'POST',
            data: {
                code,
                codeTwoFactor: userTwoFactor.codeTwofactor
            },
            headers: {
                Authorization: `Bearer ${userTwoFactor.codeTwofactor === 201 ? tokenActive : userTwoFactor.access_token}`,
            },
        });

        if (res)
            setUser(res);

        return res;
    };

    const singInWithGoogle = async () => {
        try {
            setLoading((prevValue) => prevValue + 1);

            const res: UserCredential = await signInWithPopup(auth, GoogleProvider);
            const access_token = await (await res.user.getIdToken()).toString();

            const stusDelay = await initDelay().then(() => true);

            const twoFactor = await getInitTwoFactor({ access_token });

            setUserTwoFactor({ ...twoFactor, email: res.user.email, access_token });

            return stusDelay;
        } catch (error) {
            setErrors((prevState) => {
                if (!prevState) return [error];

                return [...prevState, error];
            });

            setUserTwoFactor(null);
            return null;
        } finally {
            setLoading((prevValue) => prevValue - 1);
        }
    }

    const singInWithApple = async () => {
        try {
            setLoading((prevValue) => prevValue + 1);

            const res: UserCredential = await signInWithPopup(auth, AppleProvider);
            const access_token = await (await res.user.getIdToken()).toString();

            const stusDelay = await initDelay().then(() => true);

            const twoFactor = await getInitTwoFactor({ access_token });

            if (!twoFactor) return twoFactor;

            setUserTwoFactor({ ...twoFactor, email: res.user.email, access_token });

            return stusDelay;
        } catch (error) {
            setErrors((prevState) => {
                if (!prevState) return [error];

                return [...prevState, error];
            });

            setUserTwoFactor(null);
            return error;
        } finally {
            setLoading((prevValue) => prevValue - 1);
        }
    }

    const getInitTwoFactor = async ({ access_token }: { access_token: string }) => {
        const res = await customAxios({
            url: AUTH_SERVICE.GET_2FA,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });

        if (res && res.codeTwofactor && res.codeTwofactor === 201)
            setTokenActive(res.active_token);
        
        return res;
    }

    const registerPassKey = async ({ email }: { email: string }) => {

        const options = await customAxios({
            url: AUTH_SERVICE.REGISTER_OPT_KEYPASS,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${tokenActive}`,
            },
            data: { email },
        });

        if (!options) return options;

        let res: any;

        try {
            res = await startRegistration(options);
        } catch (error) {
            setErrors((prevState) => {
                if (!prevState) return [error];

                return [...prevState, error];
            });
            return null;
        }

        const verificationResp = await customAxios({
            url: AUTH_SERVICE.VERIFY_REGISTER_KEYPASS,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${tokenActive}`,
            },
            data: {
                email,
                payload: res,
            },
        });

        if (verificationResp.verified) {
            setUser({
                ...verificationResp
            })
        }

        return verificationResp
    }

    const authenticatePassKey = async ({ email }: { email: string }) => {
        const options = await customAxios({
            url: AUTH_SERVICE.AUTHENTICATE_OPT_KEYPASS,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${tokenActive}`,
            },
            data: { email },
        })

        if (!options) return options;

        let attResp;

        try {
            attResp = await startAuthentication(options);
        } catch (error) {
            setErrors((prevState) => {
                if (!prevState) return [error];

                return [...prevState, error];
            });
            return null;
        }

        const verificationResp = await customAxios({
            url: AUTH_SERVICE.VERIFY_AUTHENTICATE_KEYPASS,
            method: 'POST',
            data: {
                email,
                payload: attResp,
            },
        });

        if (verificationResp.verified) {
            setUser({
                ...verificationResp
            })
        }

        return verificationResp;

    }

    const logOut = async () => {
        try {
            setLoading((prevValue) => prevValue + 1);
            setUser(null);
            await signOut(auth);

            navigate('/auth');
        } catch (error) {
            console.log(error);
        } finally {
            setLoading((prevValue) => prevValue - 1);
        }
    }

    const initDelay = () => {
        return new Promise((resolve) => setTimeout(resolve, 3500));
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, setUserTwoFactor, setError);

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        setIsAuthenticate(Boolean(user));
    }, [user])


    return {
        signUp,
        singIn,
        singInWithGoogle,
        singInWithApple,
        logOut,
        authenticatePassKey,
        error,
        isAuthenticated,
        verifyCode,
        settingTwoFactor,
        userTwoFactor,
        userActive,
        activeCode,
        registerPassKey,
        sendCode,
        setUserTwoFactor,
    }
}