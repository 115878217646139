import { useState } from "react";

export const useLoading = () => {
    const [loading, setLoading] = useState(0);
    const [loadingPartial, setLoadingPartial] = useState<string[]>([]);
    
    return {
        loading,
        setLoading,
        loadingPartial,
        setLoadingPartial
    } 
}