import axios, { AxiosResponse } from 'axios';
import { useLoadingContext } from '../loading/LoadingContext.provider';
import { useErrorsContext } from '../error/ErrorContext.provider';

interface ParamsReques {
    url: string;
    method: string;
    partial?: string;
    data?: any;
    headers?: any;
}

export const useService = () => {
    const { setLoading, setLoadingPartial } = useLoadingContext();
    const { setErrors } = useErrorsContext();

    const customAxios = async(props: ParamsReques) => {
        try {
            if (!props.partial)
                setLoading((prevValue) => prevValue + 1);
            else 
                setLoadingPartial((prevValue) => [...prevValue, props.partial as string]);
    
            delete props.partial;
    
            const config: any = { ...props };
    
            const response: AxiosResponse = await axios(config);
    
            return response.data;
        } catch (error) {
            setErrors((prevState) => {
                if (!prevState) return [error];
    
                return [...prevState, error];
            });
            return null;
        } finally {
            if (!props.partial)
                setLoading((prevValue) => prevValue - 1);
            else 
                setLoadingPartial((prevValue) => 
                    prevValue.filter((item) => item !== props.partial as string
                ));
        }
    }

    
    return { customAxios };
}