import React, { createContext, useContext, FC } from 'react';
import { IUseServiceContext } from './CustomService.types';
import { IPropsContext } from '../../../schemas/interface/IPropsContext.types';
import { useService } from './useService.service';


const defaultServiceContext: IUseServiceContext = {
  customAxios: () => {}
};

export const ServiceContext = createContext<IUseServiceContext>(defaultServiceContext);

export const useServiceContext = () => useContext(ServiceContext);

export const ServiceContextProvider: FC<IPropsContext> = ({ children }) => {
  const values = useService();

  return <ServiceContext.Provider value={values}>{children}</ServiceContext.Provider>;
};
